import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as EtenimLogo } from "../../assets/icons/etenim-logo.svg";
import { ReactComponent as PasswordHidden } from "../../assets/icons/password-hidden.svg";
import { ReactComponent as PasswordShown } from "../../assets/icons/password-shown.svg";
import { Button } from "../../components/Button";
import { PaddedCard } from "../../components/cards/PaddedCard";
import { CheckboxInput } from "../../components/CheckboxInput";
import { CustomText } from "../../components/CustomText";
import { TextInput } from "../../components/TextInput";
import Colors from "../../utils/Colors";
import { useQueryClient } from "react-query";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { tokenAtom, userIdAtom } from "../../state/state";
import { isMobile } from "react-device-detect";
import { useLogin } from "../../hooks/useLogin";

const backgroundImage = require("../../assets/images/etenim-background.png");

const StyledEtenimLogo = styled(EtenimLogo)`
  position: absolute;
  top: ${isMobile ? 70 : 90}px;
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  background: url(${backgroundImage}) repeat 0 0;
  background-color: ${Colors.background};
  min-height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
`;

const OptionsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

interface IFormInput {
  email: string;
  password: string;
  rememberMe?: boolean;
}

export const LoginPage = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const setToken = useSetRecoilState(tokenAtom);
  const setUserId = useSetRecoilState(userIdAtom);
  const [passwordShown, setPasswordShown] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    shouldFocusError: true,
  });
  const navigate = useNavigate();
  const { mutateAsync: login, isLoading, error, isError } = useLogin();

  const onSubmit: SubmitHandler<IFormInput> = async (values) => {
    const resp = await login(values, {
      onSuccess: async ({ data }) => {
        if (data.payload?.token) {
          setToken(data?.payload.token);
          setUserId(data.payload.userId);
          navigate("/");
        }
      },
      onSettled: async (data) => {
        await queryClient.resetQueries(["user", data?.data.payload?.userId]);
      },
    });
  };

  return (
    <LoginContainer>
      <StyledEtenimLogo />
      <PaddedCard style={{ width: "25%", maxWidth: 450 }}>
        <CustomText style={{ marginBottom: 20 }} fontWeight={400} fontSize={24}>
          {t("customerLogin")}
        </CustomText>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            name="email"
            label={t("email")}
            type="email"
            options={{
              required: { value: true, message: "Obavezno" },
            }}
            register={register}
            error={errors?.["email"]?.message}
          />
          <TextInput
            name="password"
            label={t("password")}
            type={passwordShown ? "text" : "password"}
            options={{
              required: { value: true, message: "Obavezno" },
              minLength: { value: 8, message: "Minimalna dužina je 8 znakova" },
            }}
            register={register}
            error={errors?.["password"]?.message}
            rightIcon={
              <div onClick={() => setPasswordShown(!passwordShown)}>
                {passwordShown ? (
                  <PasswordShown
                    fill={Colors.primary}
                    stroke={Colors.primary}
                  />
                ) : (
                  <PasswordHidden
                    fill={Colors.primary}
                    stroke={Colors.primary}
                  />
                )}
              </div>
            }
          />
          <OptionsRow>
            <CheckboxInput
              register={register}
              name="rememberMe"
              label="Zapamti me"
            />
            <Link
              to="/forgot-password"
              style={{ textDecoration: "none", color: Colors.primaryGreen }}
            >
              <CustomText fontSize={13}>{t("forgottenPassword")}</CustomText>
            </Link>
          </OptionsRow>

          <Button
            text={t("logIn")}
            buttonType="primary"
            type="submit"
            loading={!!isLoading}
          />
        </form>
        {isError && (
          <CustomText style={{ color: Colors.lightRed }}>
            {/*@ts-ignore*/}
            {error?.response?.data?.message}
          </CustomText>
        )}
      </PaddedCard>
    </LoginContainer>
  );
};
