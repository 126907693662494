import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as EtenimLogo } from "../../assets/icons/etenim-logo.svg";
import { Button } from "../../components/Button";
import { PaddedCard } from "../../components/cards/PaddedCard";
import { CustomText } from "../../components/CustomText";
import { TextInput } from "../../components/TextInput";
import { useUserFunctions } from "../../hooks/useUserFunctions";
import { ResetPasswordDTO } from "../../new-api/api";
import Colors from "../../utils/Colors";

const backgroundImage = require("../../assets/images/etenim-background.png");

const StyledEtenimLogo = styled(EtenimLogo)`
  position: absolute;
  top: 90px;
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  background: url(${backgroundImage}) repeat 0 0;
  background-color: ${Colors.background};
  min-height: 100%;
  background-size: 100%;
`;

export const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordDTO>({
    defaultValues: {
      email: "",
    },
    shouldFocusError: true,
  });

  const {
    resetPassword: { mutateAsync: resetPassword, isLoading: isResettingPassword, isError, error }
  } = useUserFunctions();

  const onSubmit = handleSubmit(async (values) => {
    const resp = await resetPassword(values);
    if (resp.status >= 200 && resp.status < 300) {
      //@ts-ignore
      navigate("/forgot-password/success", { state: values });
    }
  });

  return (
    <LoginContainer>
      <StyledEtenimLogo />
      <PaddedCard>
        <CustomText fontWeight={400} fontSize={24}>
          {t("forgotPassword")}
        </CustomText>
        <CustomText
          style={{ maxWidth: 310, marginBottom: 20 }}
          fontSize={13}
          lineHeight={1.5}
        >
          {t("passwordResetText")}.{t("passwordResetText2")}
        </CustomText>
        <form onSubmit={onSubmit}>
          <TextInput
            name="email"
            label="Email adresa"
            type="email"
            options={{
              required: { value: true, message: "Obavezno" },
            }}
            register={register}
            error={errors?.["email"]?.message}
          />

          <Button
            text={t("sendNewPassword")}
            buttonType="primary"
            type="submit"
            loading={isResettingPassword}
          />
        </form>
        {/*@ts-ignore*/}
        {isError && <CustomText style={{ color: Colors.lightRed }}>{error?.response?.data?.message || ''}</CustomText>}
      </PaddedCard>
    </LoginContainer>
  );
};
